<template>
  <v-text-field
    v-model="prenom"
    dense
    label="Prénom"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    student: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      prenom: '',
    }
  },
  watch: {
    student(newVal) {
      if (newVal && newVal.prenom) {
        this.prenom = newVal.prenom
      }
    },
    prenom(value) {
      if (value) {
        this.prenom = this.capitalizeFirstLetter(value)
        this.pushVar(value)
      }
    },
  },
  created() {
    if (this.student) {
      this.prenom = this.student.prenom
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      string = string.replace(/\s/g, '').toLowerCase()

      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    pushVar(value) {
      this.$emit('variableUpdated', { index: this.index, data: value })
    },
  },
}
</script>

<style>

</style>
