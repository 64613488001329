<template>
  <v-text-field
    v-model="password"
    dense
    label="Mot de passe"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    student: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    prenom: {
      type: String,
      default: '',
    },
    nom: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      password: '',
    }
  },
  computed: {
  },
  watch: {
    password(value) {
      if (value) {
        this.pushVar(this.generatePassword())
      }
    },
    prenom(value) {
      if (value) {
        this.generatePassword()
      }
    },
    nom(value) {
      if (value) {
        this.generatePassword()
      }
    },
  },
  created() {
    this.pushVar(this.generatePassword())
  },
  methods: {
    generatePassword() {
      const nom = this.nom.replace(/\s/g, '').toUpperCase()
      const prenom = this.prenom.replace(/\s/g, '').toUpperCase()
      this.password = `${nom}${prenom}2022`

      return this.password
    },
    pushVar(value) {
      this.$emit('variableUpdated', { index: this.index, data: value })
    },
  },
}
</script>

<style>

</style>
