<template>
  <v-dialog
    v-model="emaildiag"
    transition="dialog-top-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          {{ icons.mdiEmailOutline }}
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:default="emaildialog">
      <v-form>
        <v-card>
          <v-toolbar
            color="primary"
            dark
          >
            Prévisualisation e-mail
          </v-toolbar>
          <v-card-text class="mt-3">
            <v-combobox
              v-model="email.destinataire"
              label="Destinataire : "
              :items="[]"
              :error="destError"
              chips
              required
              readonly
              @change="destError = false"
            ></v-combobox>
            <v-combobox
              v-model="cc"
              readonly
              :items="ccitem"
              chips
              label="Répondre à :"
            ></v-combobox>
            <v-text-field
              v-model="email.subject"
              label="subject :"
              required
            ></v-text-field>
            <div
              class="email-area"
              v-html="email.content"
            >
            </div>
            <v-divider class="my-3"></v-divider>
          </v-card-text>
          <v-card-actions class="justify-space-between">
            <v-btn
              text
              @click="emaildialog.value = false"
            >
              Fermer
            </v-btn>
            <v-checkbox
              v-model="signature"
              label="Signature"
            ></v-checkbox>
            <v-btn
              color="primary"
              :disabled="disableBtn"
              @click="sendMail()"
            >
              Envoyer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      <v-dialog
        v-model="disableBtn"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text>
            Envoi de l'email
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </v-dialog>
</template>

<script>

import { mdiEmailOutline } from '@mdi/js'

export default {

  props: {
    subject: {
      type: String,
      default: '',
    },
    destinataire: {
      type: String,
      default: '',
    },
    to: {
      type: Number,
      default: 0,
    },
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      destError: false,
      cc: 'suivi@laformationpro.fr',
      ccitem: ['suivi@laformationpro.fr'],
      email: {
        destinataire: '',
        to: 0,
        subject: '',
        content: '',
        type: '',
      },
      signature: true,
      signaturecontent: `<div>
    <table>
        <tr>
            <td style="padding: 7px">
                <img src="https://laformationpro.fr/wp-content/uploads/2021/04/dark_logo_transparent_background.png"
                    alt="" width="200" height="80"
                    style="display:block; border-radius: 50%; margin-right: 7px; margin-top:20px; float: left">
                <div style="width: 5px; height: 120px; background:#004AAD; float: right">
            </td>
            <td style="vertical-align:top; padding:25px 14px 7px 3px">
                <strong
                    style="margin: 0; font-size:18px; color: #000000; line-height: 24px; height: 24px; display:block">Sarah
                    PARIENTI</strong>
                <p style='font-size:16px; margin: 0px 0 6px;'>
                    <span style="margin: 0; color: #000">Présidente</span>
                </p>
                <p style='font-size:14px; margin: 0px 0 6px;'>
                    <a style="text-decoration: none;" href="tel:0660946438"><span style="margin: 0; color: #000">Tel: 06
                            60 94 64 38</span></a>
                </p>
                <p style='font-size:14px; margin: 0px 0 6px;'>
                    <a style="text-decoration: none;" href="tel:0613973702"><span style="margin: 0; color: #000">David :
                            06 13 97 37 02</span></a>
                </p>
                <p style='font-size:14px; margin: 0px 0 6px;'>
                    <a style="text-decoration: none;" href="tel:0664497775"><span style="margin: 0; color: #000">Esther
                            : 06 64 49 77 75</span></a>
                </p>
                <p style='font-size:14px; margin: 0px 0 6px;'>
                    <a style="text-decoration: none;" href="tel:0663721497"><span style="margin: 0; color: #000">Anaële
                            : 06 63 72 14 97</span></a>
                </p>
            </td>
        </tr>
    </table>
</div>`,
      disableBtn: false,
      dialog: false,
      emaildiag: false,
      icons: {
        mdiEmailOutline,
      },
    }
  },
  watch: {
    content() {
      this.syncData()
    },
    signature(val) {
      if (val) {
        this.addSignature()
      } else {
        this.email.content = this.content.replace(/\r?\n/g, '<br />')
      }
    },
  },
  mounted() {
    this.syncData()
    this.addSignature()
  },
  methods: {
    addSignature() {
      if (this.$store.getters.userSignature) {
        this.email.content += this.$store.state.user.emailSignature
      } else {
        this.email.content += this.signaturecontent
      }
    },
    sendMail() {
      this.disableBtn = true
      this.destError = false

      this.$http.post(`${process.env.VUE_APP_API_URL}/email/sendmail`, this.email)
        .then(res => {
          if (res.status === 200) {
            this.$store.commit('snackMe', {
              color: 'success',
              text: 'E-mail envoyé avec succès',
              value: true,
            })
            this.emaildiag = false
          }
        })
        .catch(err => {
          if (err.response && err.response.status === 404) {
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'Pas d\'apprenant avec cet e-mail',
              value: true,
            })
            this.destError = true
          } else {
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'Une erreur est survenue pendant l\'envoi du mail',
              value: true,
            })
          }
        })
        .finally(this.disableBtn = false)
    },
    syncData() {
      this.email.destinataire = this.destinataire
      this.email.subject = this.subject
      this.email.type = this.subject
      this.email.to = this.to
      this.email.content = this.content.replace(/\r?\n/g, '<br />')
    },
  },

}
</script>

<style scoped>
.email-area {
  max-height: 250px;
  overflow-y: scroll;
}

</style>
