<template>
  <v-text-field
    v-model="email"
    dense
    label="E-mail"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    student: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      email: '',
    }
  },
  watch: {
    student(newVal) {
      if (newVal && newVal.email) {
        this.email = newVal.email
      }
    },
    email(value) {
      if (value) {
        this.pushVal(value)
      }
    },
  },
  created() {
    if (this.student) {
      this.email = this.student.email
    }
    this.pushVal(this.email)
  },
  methods: {
    pushVal(value) {
      this.$emit('variableUpdated', { index: this.index, data: value })
    },
  },
}
</script>

<style>

</style>
