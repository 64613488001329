<template>
  <div>
    <v-text-field
      v-model="rdv.date"
      dense
      label="Date prochain RDV"
    ></v-text-field>

    <v-text-field
      v-model="rdv.heure"
      dense
      label="Heure du prochain RDV"
    ></v-text-field>

    <DateTimePickerVue
      color="info"
      button-text="Modifier la date"
      @newDate="updateDateTime"
    ></DateTimePickerVue>
  </div>
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import DateTimePickerVue from '@/components/DateTimePicker.vue'

export default {
  components: {
    DateTimePickerVue,
  },
  props: {
    student: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      rdv: {
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        heure: '12:15',
      },
      icons: {
        mdiCalendar,
      },
    }
  },
  watch: {
    student(newVal) {
      if (newVal.contacts) {
        const lastContact = newVal.contacts
        lastContact.sort((a, b) => new Date(b.contactDate) - new Date(a.contactDate))

        this.updateDateTime(new Date(lastContact[0].contactDate))
      }
    },
    rdv(value) {
      if (value) {
        this.pushVar(value)
      }
    },
  },
  created() {
    this.pushVar(this.rdv)
  },
  methods: {
    pushVar(value) {
      this.$emit('variableUpdated', { index: this.index, data: value })
    },
    updateDateTime(e) {
      this.rdv.date = this.$date(e, 'dd/MM/yyyy')
      this.rdv.heure = this.$date(e, 'HH:mm')
    },
  },
}
</script>

<style>

</style>
