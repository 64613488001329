<template>
  <v-select
    v-model="gender"
    :items="genres"
    dense
    label="Genre"
  ></v-select>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      gender: 'Mr',
      genres: ['Mr', 'Mme'],
    }
  },
  watch: {
    gender(value) {
      if (value) {
        this.pushVal(value)
      }
    },
  },
  created() {
    this.pushVal(this.gender)
  },
  methods: {
    pushVal(value) {
      this.$emit('variableUpdated', { index: this.index, data: value })
    },
  },
}
</script>

<style>

</style>
